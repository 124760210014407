<template>
  <div class="grant">
    <div v-if="yfather">
      <el-button @click="createGrantDielog = true" size="small"
        >新建发放</el-button
      >
    </div>
    <div v-else>
      <el-button type="primary" @click="btnType = 0" size="small"
        >卡密管理</el-button
      >
      <el-button type="primary" @click="btnType = 1" size="small"
        >在线发放</el-button
      >
      <el-button @click="createCarmi = true" v-show="btnType === 0" size="small"
        >添加卡密</el-button
      >
      <el-button
        @click="createGrantDielog = true"
        v-show="btnType === 1"
        size="small"
        >新建发放</el-button
      >
    </div>

    <!-- 卡密管理 -->
    <div v-show="btnType === 0" style="margin-top:20px;">
      <el-table :data="CarmiTable" border style="width: 100%">
        <el-table-column label="生成方式">
          <template slot-scope="scope">
            {{ scope.row.create_type == 1 ? "系统生成" : "表格导入" }}
          </template>
        </el-table-column>

        <el-table-column label="添加时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm") }}
          </template>
        </el-table-column>

        <el-table-column prop="key_num" label="总数"></el-table-column>

        <el-table-column prop="active_num" label="已激活数"></el-table-column>

        <el-table-column prop="binding_num" label="已绑定数"></el-table-column>

        <el-table-column prop="exchange_num" label="已兑换数"></el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleCarmiTableDetail(scope.row, 1)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align:center;margin-top:20px;" class="pages">
        <el-pagination
          @size-change="handleCarmiTableSizeChange"
          @current-change="handleCarmiTableCurrentChange"
          :page-size="CarmiParams.page_size"
          :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout"
          background
          :total="CarmiParams.total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 查看卡密 -->
    <el-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      title="卡密详情"
      :visible.sync="isCarmiTableList"
      width="90%"
    >
      <div>
        <el-row :gutter="24">
          <el-col :span="4">
            <el-select
              @change="handleCarmiTableDetail"
              clearable
              style="width:100% !important;"
              v-model="CarmiTableParams.active_status"
            >
              <el-option
                v-for="item in CarmiTableListOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input
              v-model="CarmiTableParams.card_code"
              placeholder="请输入编号进行搜索"
            ></el-input>
          </el-col>
          <el-col :span="16">
            <el-button @click="handleCarmiTableDetail" type="primary"
              >搜索</el-button
            >
            <el-button @click="handleActivationCheck(0)" type="primary"
              >激活选中</el-button
            >
            <el-button @click="handleActivationCheck(1)" type="primary"
              >取消激活选中</el-button
            >
            <el-button @click="handleActivationAll(0)" type="primary"
              >激活全部</el-button
            >
            <el-button @click="handleActivationAll(1)" type="primary"
              >取消激活全部</el-button
            >
            <el-button type="primary" @click="handleClickRecord">操作日志</el-button>
          </el-col>
        </el-row>
        <div style="margin-top:20px;">
          <el-table
            :row-key="row => row.id"
            @selection-change="CarmiTableListSelectChange"
            :data="CarmiTableList"
            border
            style="width: 100%"
          >
            <!--             -->
            <el-table-column
              :reserve-selection="true"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column
              prop="id"
              label="序号"
            ></el-table-column>
            <el-table-column
              prop="card_code"
              label="编号"
            ></el-table-column>
            <el-table-column
              prop="card_key"
              label="兑换码"
            ></el-table-column>
            <el-table-column
              prop="binding_member_name"
              label="绑定用户"
            ></el-table-column>
            <el-table-column prop="binding_time" label="绑定时间" width="160">
              <template slot-scope="scope">
                {{ scope.row.binding_time | unixToDate("yyyy-MM-dd hh:mm") }}
              </template>
            </el-table-column>

            <el-table-column prop="active_status" label="状态">
              <template slot-scope="scope">
                {{ scope.row.active_status == 0 ? "禁用中" : "激活中" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="active_name"
              label="激活人"
            ></el-table-column>
            <el-table-column  prop="card_code" label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.active_status == 0"
                  size="mini"
                  type="primary"
                  @click="handleCarmiTableListActivation(scope.row, 0)"
                  >激活</el-button
                >
                <el-button
                  v-if="scope.row.active_status == 1"
                  size="mini"
                  type="primary"
                  @click="handleCarmiTableListActivation(scope.row, 1)"
                  >禁用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align:center;margin-top:20px;" class="pages">
          <el-pagination
            @size-change="handleCarmiTableListSizeChange"
            @current-change="handleCarmiTableListCurrentChange"
            :page-size="CarmiTableParams.page_size"
            :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout"
            background
            :total="CarmiTableParams.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <!-- 添加卡密 -->
    <el-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      title="生成卡密"
      :visible.sync="createCarmi"
      width="580px"
    >
      <el-tabs v-model="createCarmiType" type="card">
        <el-tab-pane label="系统生成卡密" name="1">
          <div class="tipBox">
            <p>*输入生成数量，即可一键生成卡密</p>
            <p>*您可生成卡号+卡密或仅卡密两种方式</p>
            <p>*导入的卡密默认为未激活状态</p>
          </div>
          <div class="addMealForm">
            <el-form
              :model="submitCreateCardKeysParams"
              :rules="submitCreateCardKeysRules"
              ref="submitCreateCardKeysParams"
              label-width="110px"
            >
              <el-form-item class="w200" label="生成数量" prop="num">
                <el-input-number
                  :min="1"
                  style="width: 200px;"
                  :controls="false"
                  v-model="submitCreateCardKeysParams.num"
                >
                </el-input-number
                >张
              </el-form-item>
              <el-form-item label="卡号前缀" prop="prefix_code">
                <el-input
                  style="width: 200px"
                  placeholder="请输入5位以内的字母或数字"
                  v-model="submitCreateCardKeysParams.prefix_code"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="dialog-footer">
              <el-button
                type="default"
                @click="createCarmi = false"
                size="small"
                >取消</el-button
              >
              <el-button
                type="primary"
                @click="submitCreateCardKeys"
                size="small"
                >确定</el-button
              >
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入卡密" name="2">
          <div class="tipBox b1">
            <p>*您可将已有的卡密导入至此卡中，使卡密与此卡绑定</p>
            <p>*您可导入卡号+卡密或仅卡密</p>
            <p>*卡号和卡密都不可重复</p>
            <p>*导入的卡密默认为未激活状态</p>
            <p>*卡号为6~16位字符，卡密为8~20位字符，都为数字+字母</p>
          </div>
          <div class="shep">第一步：根据需求，勾选下列内容，形成表格模板</div>
          <el-radio-group v-model="downLoadType">
            <el-radio :label="0">仅卡密</el-radio>
            <el-radio :label="1">卡号+卡密</el-radio>
          </el-radio-group>
          <br />
          <el-button
            style="margin-top: 10px;margin-bottom: 15px"
            type="default"
            @click="downTemplate"
            >下载表格模板</el-button
          >
          <div class="shep">
            第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式
          </div>
          <div class="shep">第三步：导入表格</div>
          <upload-excel-component defaultSize :on-success="excelSuccess" />
          <div class="dialog-footer">
            <el-button type="default" @click="createCarmi = false" size="small"
              >取消</el-button
            >
            <el-button type="primary" @click="submitImportCardKeys" size="small"
              >确定</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 在线发放log日志 -->
    <div v-show="btnType === 1" style="margin-top:20px">
      <el-table :data="grantList" border style="width: 100%">
        <el-table-column label="发放名单来源">
          <template slot-scope="scope">
            {{ scope.row.create_type == 1 ? "系统生成" : "表格导入" }}
          </template>
        </el-table-column>
        <el-table-column label="添加时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm") }}
          </template>
        </el-table-column>
        <el-table-column prop="group_name" label="所属客户组"></el-table-column>
        <el-table-column prop="active_num" label="份数"></el-table-column>
        <el-table-column prop="exchange_num" label="已兑换数"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click="handleCarmiTableDetail(scope.row, 2)"
              size="mini"
              type="primary"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align:center;margin-top:20px;" class="pages">
        <el-pagination
          @size-change="handlegrantListSizeChange"
          @current-change="handlegrantListCurrentChange"
          :page-size="grantListParams.page_size"
          :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout"
          background
          :total="grantListParams.total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加发放 -->
    <el-dialog
      :close-on-click-modal="false"
      :append-to-body="true"
      title="在线发放"
      :visible.sync="createGrantDielog"
      width="580px"
    >
      <el-tabs v-model="createGrantType" type="card">
        <el-tab-pane label="发放给系统用户" name="1">
          <div class="titleTop">
            <div class="right">
              <el-input
                placeholder="请输入关键字搜索"
                v-model="createGrantParams.keyword"
                class="input-with-select"
              >
                <el-button
                  @click="getMemberList"
                  slot="append"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </div>
            <el-select
              @change="changeUserList"
              v-model="createGrantParams.group_id"
              placeholder="请选择用户分组"
            >
              <el-option
                v-for="item in UserOptions"
                :key="item.group_id"
                :label="item.group_name"
                :value="item.group_id"
              ></el-option>
            </el-select>
          </div>
          <div class="tables">
            <el-table
              ref="multipleTable"
              :row-key="row => row.member_id"
              @selection-change="createGrantTableSelectChange"
              :data="createGranList"
              border
              style="width: 100%"
            >
              <el-table-column
                type="selection"
                :reserve-selection="true"
                width="55"
              ></el-table-column>
              <el-table-column
                prop="nickname"
                label="用户姓名"
              ></el-table-column>
              <el-table-column
                prop="group_name"
                label="所属分组"
              ></el-table-column>
              <el-table-column prop="mobile" label="手机号"></el-table-column>
            </el-table>
          </div>
          <div style="text-align:center;margin-top:20px;" class="pages">
            <el-pagination
              @size-change="handlecreateGrantSizeChange"
              @current-change="handlecreateGrantCurrentChange"
              :page-size="createGrantParams.page_size"
              :page-sizes="MixinPageSizes"
              :layout="MixinTableLayout"
              background
              :total="createGrantParams.total"
            >
            </el-pagination>
          </div>
          <!-- <div>
             <el-checkbox v-model="checked">短信通知</el-checkbox>
             <p>【短信签名】发券通知：尊敬的客户，您收到一张卡券，请使用手机号或[卡密]，前往 [链接] 进行兑换，如有疑问请联系客服：[卡券的客服号码]。</p>
          </div> -->
          <div class="dialog-footer">
            <el-button
              type="default"
              size="small"
              @click="createGrantDielog = false"
              >取消</el-button
            >
            <el-button type="primary" size="small" @click="submitCreateGrant"
              >确定</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="表格导入发放名单" name="2">
          <div class="tipBox b2">
            <p>*您可根据客户的需求，导入不同格式的数据，如手机号、工号、姓名</p>
            <p>*导入的表格将在客户管理总新建一个客户组</p>
            <p>*姓名和分组可以为空，手机号不能为空</p>
            <p>*工号为6~16位字符，卡密为8~20位字符，都为数字+字母</p>
          </div>
          <div class="shep">第一步：根据需求，勾选下列内容，形成表格模板</div>
          <el-radio-group v-model="downLoadType1">
            <el-radio :label="0">导入手机号</el-radio>
          </el-radio-group>
          <br />
          <el-button
            style="margin-top: 10px;margin-bottom: 15px"
            type="default"
            @click="downTemplate1"
            >下载表格模板</el-button
          >
          <div class="shep">
            第二步：编辑表格，请不要修改/删除表格中的表头文字，且不要修改表格的格式
          </div>
          <div class="shep">第三步：导入表格</div>
          <upload-excel-component defaultSize :on-success="excelSuccess1" />
          <div class="dialog-footer">
            <el-button
              type="default"
              size="small"
              @click="createGrantDielog = false"
              >取消</el-button
            >
            <el-button type="primary" size="small" @click="submitCreateGrant1"
              >确定</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
<!-- 操作记录 -->
    <el-dialog
      title="操作记录"
      center
      :visible.sync="recordFlag"
      width="80%"
      class="popinfo"
      append-to-body
    >
      <en-table-layout
        toolbar
        pagination
        :tableData="recordTableData.data"
        :loading="loading"
      >
        <div slot="toolbar" class="inner-toolbar">
          <el-select
            size="medium"
            v-model="recordinform.oper_type"
            clearable
            style="width:150px !important;margin-left:10px"
          >
            <el-option label="全部操作记录" value></el-option>
            <el-option label="激活" value="2"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
          <el-input
            v-model="recordinform.key_word"
            size="medium"
            style="width:220px"
            clearable
            placeholder="请输入卡号/卡密进行搜索"
          />
          <el-button size="small" type="primary" @click="getrecordList"
            >搜索</el-button
          >
          <el-button size="small" type="primary" @click="ExportGetrecordList"
            >导出全部</el-button
          >
        </div>
        <template slot="table-columns">
          <el-table-column label="编号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="goods_name" label="操作时间" width="160">
            <template slot-scope="scope">{{
              scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss")
            }}</template>
          </el-table-column>
          <el-table-column prop="oper_type" label="操作类型">
            <template slot-scope="scope">{{
              scope.row.oper_type === 1 ? "禁用" : "激活"
            }}</template>
          </el-table-column>
          <el-table-column prop="card_code" label="卡号" />
          <el-table-column prop="card_key" label="卡密" />
          <el-table-column prop="opr_name" label="操作人" />
          <el-table-column prop="ip" label="ip地址" />
        </template>
        <el-pagination
          slot="pagination"
          v-if="recordTableData"
          @size-change="handlePageSizeChange1"
          @current-change="handlePageCurrentChange1"
          :current-page="recordTableData.page_no"
          :page-size="recordTableData.page_size"
          :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout"
          background
          :total="recordTableData.data_total"
        ></el-pagination>
      </en-table-layout>
    </el-dialog>
  </div>
</template>

<script>
import * as API_HotGoods from "@/api/hotGoods";
import * as API_coupon from "@/api/coupon";
import UploadExcelComponent from "@/components/UploadExcel";
import { handleDownload } from "@/utils";
// import Sortable from "sortablejs";
import { UE } from "@/components";
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {
  name: "hotSellingGoods",
  components: {
    EnTableLayout,
    [UE.name]: UE,
    UploadExcelComponent
  },
  props: {
    yfather: Number
  },
  data() {
    return {
      loading:false,
      id: null,
      btnType: 0,
      CarmiParams: {
        card_id: null,
        page_size: 20,
        page_no: 1,
        total: 0
      },
      CarmiTable: [],

      isCarmiTableList: false,
      CarmiTableParams: {
        log_id: "",
        provide_log_id: "",
        card_id: "",
        card_code: "",
        active_status: "",
        page_size: 20,
        page_no: 1,
        total: 0
      },
      CarmiTableList: [],
      CarmiTableListChange: [],
      CarmiTableListOptions: [
        { value: "0", label: "未激活" },
        { value: "1", label: "已激活" }
      ],

      createCarmi: false,
      createCarmiType: "1",
      submitCreateCardKeysParams: {
        card_id: "",
        num: "",
        prefix_code: ""
      },
      submitCreateCardKeysRules: {
        num: [{ required: true, message: "请输入生成数量", trigger: "change" }]
      },
      downLoadType: 0,
      submitImportCardKeysParams: {
        card_id: "",
        card_value: "0",
        cards_key_dolist: []
      },

      grantList: [],
      grantListParams: {
        card_id: "",
        page_size: 20,
        page_no: 1,
        total: 0
      },

      createGrantType: "1",
      createGrantParams: {
        // shop_id: this.$store.getters.shopInfo.shop_id, // 店铺
        page_size: 5,
        page_no: 1,
        keyword: "",
        group_id: "" // 分组id
      },
      createGranList: [],
      createGranSelect: [],
      UserOptions: [],
      createGrantDielog: false,
      submitCreateGrantParams: {
        card_id: "", // 卡券id
        member_ids: [], // 用户id
        member_names: [], // 用户姓名集合
        group_id: "" //用户组id
      },

      downLoadType1: 0,
      cardMemberVO: {
        card_id: "",
        member_list: {}
      },
      // 查看操作记录
      recordFlag: false,
      recordTableData: "",
      recordinform: {
        page_size: 20,
        page_no: 1,
        total: 0,
        oper_type: "",
        key_word: ""
      }
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getCardLogList();
    this.getProvideLog();
    this.getUserlist();
    if (this.yfather) {
      this.id = this.yfather;
      this.btnType = 1;
    }
  },
  methods: {
    // 导出操作记录
    ExportGetrecordList() {
      let obj = {};
      obj.card_id = this.yfather.card_id;
      obj.log_id = this.yfather.log_id;
      obj.page_no = 0;
      obj.page_size = 0;
      API_coupon.getCardsKeyOperationLog(obj)
        .then(res => {
          const json = {
            sheet_name: "操作日志",
            sheet_values: res.data.map((item, idx) => ({
              "编号": idx + 1,
              "操作时间": Foundation.unixToDate(item.create_time),
              "操作类型": item.oper_type === 1 ? "禁用" : "激活",
              "卡号": item.card_code,
              "卡密": item.card_key,
              "操作人": item.opr_name,
              "ip地址": item.ip
            }))
          };
          this.MixinExportJsonToExcel(json, "操作日志");
        })
        .catch(_ => {
          this.loading = false;
        });
    },
    // 查看操作记录
    handleClickRecord() {
      this.recordFlag = true;
      this.getrecordList();
    },
    getrecordList() {
      this.loading = true;
      let obj = {};
      obj.card_id = this.yfather.card_id;
      obj.provide_log_id = this.yfather.log_id;
      obj.page_no = this.recordinform.page_no;
      obj.page_size = this.recordinform.page_size;
      obj.oper_type = this.recordinform.oper_type;
      obj.key_word = this.recordinform.key_word;
      API_coupon.getCardsKeyOperationLog(obj)
        .then(res => {
          this.loading = false;
          this.recordTableData = res;
          this.recordinform.total = res.data_total;
          this.recordinform.page_no = res.page_no;
          this.recordinform.page_size = res.page_size;
        })
        .catch(_ => {
          this.loading = false;
        });
    },
    // 操作记录分页
    handlePageSizeChange1(val) {
      this.recordinform.page_size = val;
      this.getrecordList();
    },
    // 操作记录分页
    handlePageCurrentChange1(val) {
      this.recordinform.page_no = val;
      this.getrecordList();
    },
    getCardLogList() {
      // 卡密日志列表
      this.CarmiParams.card_id = this.id;
      API_HotGoods.getCardLogList(this.CarmiParams).then(res => {
        this.CarmiTable = res.data;
        this.CarmiParams.page_no = res.page_no;
        this.CarmiParams.page_size = res.page_size;
        this.CarmiParams.total = res.data_total;
      });
    },
    handleCarmiTableSizeChange(val) {
      this.CarmiParams.page_size = val;
      this.getCardLogList();
    },
    handleCarmiTableCurrentChange(val) {
      this.CarmiParams.page_no = val;
      this.getCardLogList();
    },
    handleCarmiTableDetail(row, num) {
      // 查看卡密/卡密列表
      if (num == 1) {
        this.CarmiTableParams.log_id = row.id;
        this.CarmiTableParams.provide_log_id = "";
      }
      if (num == 2) {
        this.CarmiTableParams.log_id = "";
        this.CarmiTableParams.provide_log_id = row.id;
      }
      this.CarmiTableParams.card_id = row.card_id;
      API_HotGoods.getcardKeysList(this.CarmiTableParams).then(res => {
        this.CarmiTableList = res.data;
        this.CarmiTableParams.page_size = res.page_size;
        this.CarmiTableParams.page_no = res.page_no;
        this.CarmiTableParams.total = res.data_total;
        this.isCarmiTableList = true;
      });
    },
    handleCarmiTableListSizeChange(val) {
      this.CarmiTableParams.page_size = val;
      this.handleCarmiTableDetail();
    },
    handleCarmiTableListCurrentChange(val) {
      this.CarmiTableParams.page_no = val;
      this.handleCarmiTableDetail();
    },
    handleCarmiTableListActivation(row, num) {
      // 激活当前
      let arr = [];
      arr.push(row.id);
      API_HotGoods.updateCardsKeyStatus({
        operate_all: 0,
        ids: arr,
        card_id: this.id,
        active_status: num === 0 ? 1 : 0
      }).then(res => {
        this.$message({
          type: "success",
          message: num === 0 ? "激活成功" : "禁用成功"
        });
        this.handleCarmiTableDetail(row);
      });
    },
    CarmiTableListSelectChange(val) {
      this.selectCarmiTable = val;
    },
    handleActivationCheck(num) {
      // 批量激活
      let arr = [];
      this.selectCarmiTable.map(item => {
        arr.push(item.id);
      });
      API_HotGoods.updateCardsKeyStatus({
        operate_all: 0,
        ids: arr,
        card_id: this.id,
        active_status: num === 0 ? 1 : 0
      }).then(res => {
        this.selectCarmiTable = [];
        this.$message({
          type: "success",
          message: num === 0 ? "选中项激活成功" : "选中项禁用成功"
        });
        this.handleCarmiTableDetail();
      });
    },
    handleActivationAll(num) {
      // 操作全部
      API_HotGoods.updateCardsKeyStatus({
        operate_all: 1,
        ids: [0],
        card_id: this.id,
        active_status: num === 0 ? 1 : 0
      }).then(res => {
        this.selectCarmiTable = [];
        this.$message({
          type: "success",
          message: num === 0 ? "全部激活成功" : "全部禁用成功"
        });
        this.handleCarmiTableDetail();
      });
    },
    submitCreateCardKeys() {
      //系统生成卡密
      this.submitCreateCardKeysParams.card_id = this.id;
      API_HotGoods.submitCreateCardKeys(this.submitCreateCardKeysParams).then(
        res => {
          this.$message({ type: "success", message: "卡密生成成功" });
          this.createCarmi = false;
          this.getCardLogList();
        }
      );
    },
    downTemplate() {
      // 下载模板
      let tHeaders = [],
        filterVals = [];
      if (this.downLoadType == 0) {
        tHeaders = ["卡密"];
        filterVals = ["yyyy"];
      }
      if (this.downLoadType == 1) {
        tHeaders = ["卡号", "卡密"];
        filterVals = ["xxxx", "yyyy"];
      }
      handleDownload(
        this.integralImportData,
        tHeaders,
        filterVals,
        "卡密导入模板"
      );
    },
    excelSuccess({ results }) {
      this.submitImportCardKeysParams.cards_key_dolist = [];
      results.map(item => {
        this.submitImportCardKeysParams.cards_key_dolist.push({
          card_code: item["卡号"],
          card_key: item["卡密"]
        });
      });
    },
    submitImportCardKeys() {
      // 导入表格生成卡密
      this.submitImportCardKeysParams.card_id = this.id;
      API_HotGoods.submitImportCardKeys(this.submitImportCardKeysParams).then(
        res => {
          this.$message({ type: "success", message: "卡密生成成功" });
          this.createCarmi = false;
          this.getCardLogList();
        }
      );
    },
    getProvideLog() {
      // 在线发放列表
      this.grantListParams.card_id = this.id;
      API_HotGoods.getProvideLog(this.grantListParams).then(res => {
        this.grantListParams.page_no = res.data_page_no;
        this.grantListParams.page_size = res.data_page_size;
        this.grantListParams.total = res.data_total;
        this.grantList = res.data;
      });
    },
    handlegrantListSizeChange(val) {
      this.grantListParams.page_size = val;
      this.getProvideLog();
    },
    handlegrantListCurrentChange(val) {
      this.grantListParams.page_no = val;
      this.getProvideLog();
    },
    getMemberList() {
      API_HotGoods.getMemberList(this.createGrantParams).then(res => {
        this.createGrantParams.page_no = res.page_no;
        this.createGrantParams.page_size = res.page_size;
        this.createGrantParams.total = res.data_total;
        this.createGranList = res.data;
      });
    },
    handlecreateGrantSizeChange(val) {
      this.createGrantParams.page_size = val;
      this.getMemberList();
    },
    handlecreateGrantCurrentChange(val) {
      this.createGrantParams.page_no = val;
      this.getMemberList();
    },
    createGrantTableSelectChange(val) {
      this.createGranSelect = JSON.parse(JSON.stringify(val));
    },
    getUserlist() {
      let info = {
        page_size: 0,
        page_no: 0
      };
      API_HotGoods.getUserlist(info).then(res => {
        this.UserOptions = res.data;
      });
    },
    changeUserList(e) {
      this.submitCreateGrantParams.group_id = e;
      this.$refs.multipleTable.clearSelection(); // 每次变换清空一次数据
      this.getMemberList();
    },
    submitCreateGrant() {
      // 发放给系统用户提交
      if (this.createGranSelect.length == 0) {
        this.$message.error("请选择发放的用户");
        return;
      }
      this.submitCreateGrantParams.card_id = this.id;
      this.submitCreateGrantParams.member_ids = [];
      this.submitCreateGrantParams.member_names = [];
      this.createGranSelect.map(item => {
        this.submitCreateGrantParams.member_ids.push(item.member_id);
        this.submitCreateGrantParams.member_names.push(item.nickname);
      });
      if (!this.submitCreateGrantParams.group_id) {
        this.submitCreateGrantParams.group_id = this.createGranSelect[0].group_id;
      }
      API_HotGoods.bindingMembers(this.submitCreateGrantParams).then(res => {
        this.$message({ title: "提示", message: "成功发放", type: "success" });
        this.createGrantDielog = false;
        this.getProvideLog();
      });
    },
    downTemplate1() {
      // 下载模板进行编辑
      let tHeaders = ["group_name", "mobile", "nickname"];
      handleDownload(this.integralImportData, tHeaders);
    },
    excelSuccess1({ results }) {
      this.cardMemberVO.card_id = this.id;
      this.cardMemberVO.member_list = results;
    },
    submitCreateGrant1() {
      API_HotGoods.importBindingMembers(this.cardMemberVO).then(res => {
        this.$message({ title: "提示", message: "成功发放", type: "success" });
        this.createGrantDielog = false;
        this.getProvideLog();
      });
    }
  }
};
</script>

<style  lang="scss" scoped>
.inner-toolbar {
  display: block;
  padding: 0px;
}
/deep/ div.toolbar {
  display: block;
  height: auto;
  overflow: hidden;
  padding: 20px 0;
}
/deep/ .popinfo {
  .el-dialog__body {
    border: 1px solid #e5e5e5;
    .el-table {
      border: 1px solid #e5e5e5;
    }
  }
}
.grant {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.stepsBox {
  margin-top: 20px;
}
.dialog-footer {
  float: right;
  margin-top: 20px;
}
.tipBox {
  width: 440px;
  background: rgba(242, 242, 242, 1);
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 20px;
}
.tipBox p {
  line-height: 30px;
  font-size: 14px;
  color: #666;
  margin: 0;
}
.tipBox.b1 p {
  line-height: 24px;
  font-size: 12px;
}
.tipBox.b2 {
  width: 550px;
}
.tipBox.b2 p {
  line-height: 24px;
  font-size: 12px;
}
.shep {
  margin-bottom: 15px;
}

.titleTop {
  margin-bottom: 10px;
}
.titleTop .right {
  float: right;
}
</style>
